import '../sass/project.scss';

let initTooltips = function () {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
}

let initToggleFlags = function () {
    /* Toggle Feature Flags */
    const TOGGLE_URL = '/user/flags/'
    const toggleFlag = async function (flag, op) {
        let csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value;
        await fetch(TOGGLE_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken,
            },
            body: JSON.stringify({
                flag: flag,
                op: op,
            })
        });
    };

    document.querySelectorAll('[data-toggle="flag"]').forEach(function (element) {
        element.addEventListener('click', async function (evt) {
            let dataset = evt.target.dataset;
            let action;
            if (dataset.op === "input") {
                action = evt.target.checked ? "set" : "unset";
            } else if (dataset.op === "inverse-input") {
                action = evt.target.checked ? "unset" : "set";
            } else {
                action = dataset.op;
            }
            await toggleFlag(dataset.flag, action);
        })
    });
}

window.addEventListener('DOMContentLoaded', () => {
    initTooltips();
    initToggleFlags();
});
